import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import axios from '../../utils/axios';
import { history } from '../../routers/AppRouter';

import { startGetLandlordProfile, startGetRentalPropertyTenant } from './landlordApplication';
import { startGetTenantProfile } from './tenantApplication';

const initialState = {
  templateDocuments: {},
  tenantDocumentList: [],
  landlordDocumentList: [],
  loading: false,
  docLoading: {},
};

const slice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    documentsBegin: (documents, action) => {
      documents.loading = true;
      const { documentId } = action.payload || {};
      if (documentId) {
        documents.docLoading = {
          [documentId]: true,
        };
      }
    },
    documentsEnd: (documents, action) => {
      documents.loading = false;
      const { documentId } = action.payload || {};
      if (documentId) {
        documents.docLoading = {
          [documentId]: false,
        };
      }
    },

    getTemplateDocument: (documents, action) => {
      documents.templateDocuments = action.payload;
      documents.loading = false;
    },
    downloadTemplateDocument: (documents) => {
      documents.loading = false;
    },

    // LANDLORD
    uploadLandlordDocument: (documents) => {
      documents.loading = false;
      documents.docLoading = {};
    },
    deleteLandlordDocument: (documents, action) => {
      documents.loading = false;
      const { documentId } = action.payload || {};
      if (documentId) {
        const loading = documents.docLoading;
        delete loading[documentId];
        documents.docLoading = loading;
      }
    },
    downloadLandlordDocument: (documents) => {
      documents.loading = false;
    },

    // RENTAL PROPERTY TENANT
    uploadRentalPropertyTenantDocument: (documents) => {
      documents.loading = false;
    },
    downloadRentalPropertyTenantDocument: (documents) => {
      documents.loading = false;
    },
    deleteRentalPropertyTenantDocument: (documents) => {
      documents.loading = false;
    },

    // TENANT
    uploadTenantDocument: (documents) => {
      documents.loading = false;
      documents.docLoading = {};
    },
    deleteTenantDocument: (documents, action) => {
      documents.loading = false;
      const { documentId } = action.payload || {};
      if (documentId) {
        const loading = documents.docLoading;
        delete loading[documentId];
        documents.docLoading = loading;
      }
    },
    downloadTenantDocument: (documents) => {
      documents.loading = false;
    },

    // GET DOCUMENTS
    uploadAdditionalTenantDocument: (documents) => {
      documents.loading = false;
    },
    uploadUtilityTenantDocument: (documents) => {
      documents.loading = false;
    },
    getTenantDocumentList: (documents, action) => {
      documents.loading = false;
      documents.tenantDocumentList = action.payload;
    },

    getLandlordDocumentList: (documents, action) => {
      documents.loading = false;
      documents.landlordDocumentList = action.payload;
    },
    uploadAdditionalLandlordDocument: (documents) => {
      documents.loading = false;
    },
    downloadAdditionalLandlordDocument: (documents) => {
      documents.loading = false;
    },
  },
});


// GET_TEMPLATE_DOCUMENT
export const startGetTemplateDocument = (documentId) => async (dispatch, getState) => {
  dispatch(documentsBegin());
  try {
    const response = await axios.get(`${baseURL}/documents/${documentId}`);
    // {
    //   "documentURL": "https://cd-generic-stage-public-docs.s3-us-west-1.amazonaws.com/W-9.pdf"
    // }

    const documents = getState().documents.templateDocuments;
    const updated = {
      ...documents,
      [documentId]: response.data.documentURL,
    };
    dispatch(getTemplateDocument(updated));
  } catch (error) {
    console.log(error.response);
    dispatch(documentsEnd());
  }
};

// UPLOAD_LANDLORD_DOCUMENT - New logic
export const startUploadLandlordDocument = (payload) => async (dispatch, getState) => {
  dispatch(documentsBegin());
  try {
    const { landlordProfileID } = getState().landlordApplication.profile;
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    for (const type in payload) {
      const files = payload[type];
      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append('documentFile', files[i]);
        const response = await axios.post(`${baseURL}/landlordProfiles/${landlordProfileID}/${type}`, formData, config);
      }
    }
    dispatch(uploadLandlordDocument());
    dispatch(startGetLandlordProfile());
    toast.success('Documents Successfully Uploaded!');
    history.push('/application/landlord');
  } catch (error) {
    console.log(error.response);
    dispatch(documentsEnd());
  }
};

// DOWNLOAD_LANDLORD_DOCUMENT
export const startDownloadLandlordDocument = (documentId) => async (dispatch, getState) => {
  dispatch(documentsBegin());
  try {
    const response = await axios({ url: `${baseURL}/landlordProfiles/documents/${documentId}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const fileName = response.headers['content-disposition'].split('filename=')[1];
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(downloadLandlordDocument(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(documentsEnd());
  }
};

// DELETE_LANDLORD_DOCUMENT
export const startDeleteLandlordDocument = (documentId) => async (dispatch, getState) => {
  dispatch(documentsBegin({ documentId }));
  try {
    const response = await axios.delete(`${baseURL}/landlordProfiles/documents/${documentId}`);
    await dispatch(startGetLandlordDocumentList());
    history.push('/application/landlord/documents');
    toast.success('Documents Successfully Deleted!');
    dispatch(documentsEnd({ documentId }));
    dispatch(deleteLandlordDocument({ documentId }));
  } catch (error) {
    console.log(error.response);
    dispatch(documentsEnd());
  }
};

// UPLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT
export const startUploadRentalPropertyTenantDocument = (payload) => async (dispatch, getState) => {
  dispatch(documentsBegin());
  try {
    const { rentalPropertyTenantID } = getState().landlordApplication.tenant;
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    for (const type in payload) {
      const formData = new FormData();
      formData.append('documentFile', payload[type]);
      const response = await axios.post(`${baseURL}/rentalPropertyTenants/${rentalPropertyTenantID}/${type}`, formData, config);
    }
    dispatch(uploadRentalPropertyTenantDocument());
    toast.success('Documents Successfully Uploaded!');
    history.push('/application/landlord');
  } catch (error) {
    console.log(error.response);
    dispatch(documentsEnd());
  }
};

// DOWNLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT
export const startDownloadRentalPropertyTenantDocument = (documentID) => async (dispatch, getState) => {
  dispatch(documentsBegin());
  try {
    const response = await axios({ url: `${baseURL}/rentalPropertyTenants/documents/${documentID}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const fileName = response.headers['content-disposition'].split('filename=')[1];
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(downloadRentalPropertyTenantDocument(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(documentsEnd());
  }
};

// DELETE_LANDLORD_DOCUMENT
export const startDeleteRentalPropertyTenantDocument = (documentId, rentalPropertyTenantID) => async (dispatch, getState) => {
  dispatch(documentsBegin());
  try {
    const response = await axios.delete(`${baseURL}/rentalPropertyTenants/documents/${rentalPropertyTenantID}/${documentId}`);
    dispatch(deleteRentalPropertyTenantDocument());
    history.push(`/application/landlord/tenant/${rentalPropertyTenantID}/documents`);
    toast.success('Documents Successfully Deleted!');
    await dispatch(startGetRentalPropertyTenant(rentalPropertyTenantID));
  } catch (error) {
    console.log(error.response);
    dispatch(documentsEnd());
  }
};

// UPLOAD_TENANT_DOCUMENT
export const startUploadTenantDocument = (payload, isRecert) => async (dispatch, getState) => {
  dispatch(documentsBegin());
  try {
    const { tenantProfileID } = getState().tenantApplication.profile;
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    for (const type in payload) {
      const files = payload[type];
      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append('documentFile', files[i]);
        formData.append('type', type);
        const response = await axios.post(`${baseURL}/tenantProfile/${tenantProfileID}/${type}`, formData, config);
      }
    }
    dispatch(uploadTenantDocument());
    dispatch(startGetTenantProfile());
    if (isRecert) {
      dispatch(startGetDocumentList());
    }
    toast.success('Documents Successfully Uploaded!');
    history.push('/application/tenant');
  } catch (error) {
    console.log(error.response);
    dispatch(documentsEnd());
  }
};

// UPLOAD_UTILITY_TENANT_DOCUMENT
export const startUploadUtilityDocument = (file) => async (dispatch, getState) => {
  dispatch(documentsBegin());
  try {
    const { tenantProfileID } = getState().tenantApplication.profile;
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    formData.append('documentFile', file);
    const response = await axios.post(`${baseURL}/tenantProfile/${tenantProfileID}/UTILITY`, formData, config);
    dispatch(uploadUtilityTenantDocument());
    dispatch(startGetTenantProfile());
    dispatch(startGetDocumentList());
    toast.success('Utility Document Successfully Uploaded!');
  } catch (error) {
    console.log(error.response);
    dispatch(documentsEnd());
  }
};

// DOWNLOAD_TENANT_DOCUMENT
export const startDownloadTenantDocument = (documentId) => async (dispatch, getState) => {
  dispatch(documentsBegin());
  try {
    const response = await axios({ url: `${baseURL}/tenantProfile/documents/${documentId}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const fileName = response.headers['content-disposition'].split('filename=')[1];
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    dispatch(downloadTenantDocument(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(documentsEnd());
  }
};

// DELETE_TENANT_DOCUMENT
export const startDeleteTenantDocument = (documentId) => async (dispatch, getState) => {
  dispatch(documentsBegin({ documentId }));
  try {
    const response = await axios.delete(`${baseURL}/tenantProfile/documents/${documentId}`);
    await dispatch(startGetTenantProfile());
    await dispatch(startGetDocumentList());
    toast.success('Documents Successfully Deleted!');
    dispatch(documentsEnd({ documentId }));
    dispatch(deleteTenantDocument({ documentId }));
  } catch (error) {
    console.log(error.response);
    dispatch(documentsEnd());
  }
};

// UPLOAD_ADDITIONAL_TENANT_DOCUMENT
export const startUploadAdditionalTenantDocument = (file, uploadOnly, toastMessage = '') => async (dispatch, getState) => {
  dispatch(documentsBegin());
  try {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    formData.append('documentFile', file);
    const response = await axios.post(`${baseURL}/tenant/additional/`, formData, config);
    dispatch(uploadAdditionalTenantDocument());
    dispatch(startGetTenantProfile());
    if (!uploadOnly) {
      history.push('/application/tenant');
    }
    toast.success(toastMessage || 'Additional Document Successfully Uploaded!');
    dispatch(startGetDocumentList());
  } catch (error) {
    console.log(error.response);
    dispatch(documentsEnd());
  }
};

// GET_TENANT_DOCUMENT_LIST
export const startGetDocumentList = () => async (dispatch, getState) => {
  dispatch(documentsBegin());
  try {
    const response = await axios.get(`${baseURL}/tenant/documents`);
    dispatch(getTenantDocumentList(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(documentsEnd());
  }
};

// GET_LANDLORD_DOCUMENT_LIST
export const startGetLandlordDocumentList = () => async (dispatch, getState) => {
  dispatch(documentsBegin());
  try {
    const response = await axios.get(`${baseURL}/landlord/documents`);
    dispatch(getLandlordDocumentList(response.data));
  } catch (error) {
    console.log(error.response);
    dispatch(documentsEnd());
  }
};

// UPLOAD_ADDITIONAL_LANDLORD_DOCUMENT
export const startUploadAdditionalLandlordDocument = (file) => async (dispatch) => {
  dispatch(documentsBegin());
  try {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    formData.append('documentFile', file);
    const response = await axios.post(`${baseURL}/landlord/additional/`, formData, config);
    await dispatch(startGetLandlordDocumentList());
    dispatch(uploadAdditionalLandlordDocument());
  } catch (error) {
    console.log(error.response);
    dispatch(documentsEnd());
  }
};

// DOWNLOAD_ADDITIONAL_LANDLORD_DOCUMENT
export const startDownloadAdditionalLandlordDocument = (documentId) => async (dispatch, getState) => {
  dispatch(documentsBegin());
  try {
    const response = await axios({ url: `${baseURL}/landlordProfiles/additional/${documentId}`, method: 'GET', responseType: 'blob' });
    const a = document.createElement('a');
    const fileName = response.headers['content-disposition'].split('filename=')[1];
    a.download = decodeURI(fileName);
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    document.body.append(a);
    a.click();
    await dispatch(startGetLandlordDocumentList());
    dispatch(downloadAdditionalLandlordDocument());
  } catch (error) {
    console.log(error.response);
    dispatch(documentsEnd());
  }
};

export const {
  documentsBegin,
  documentsEnd,
  getTemplateDocument,
  downloadTemplateDocument,
  uploadLandlordDocument,
  deleteLandlordDocument,
  downloadLandlordDocument,
  uploadRentalPropertyTenantDocument,
  downloadRentalPropertyTenantDocument,
  deleteRentalPropertyTenantDocument,
  uploadTenantDocument,
  deleteTenantDocument,
  downloadTenantDocument,
  uploadAdditionalTenantDocument,
  uploadUtilityTenantDocument,
  getTenantDocumentList,
  getLandlordDocumentList,
  uploadAdditionalLandlordDocument,
  downloadAdditionalLandlordDocument,
} = slice.actions;

export default slice.reducer;
